.Login {
  text-align: left;
  padding: 1em;
  border: 2px solid #d3d3d3;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  margin-top: 125px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}

.form {
  padding: 1em;
}

label {
  display: flex;
  font-weight: 600;
}

button {
  justify-content: flex-end;
}

.Login-title {
  font-size: 1.5em;
}

.Login-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
